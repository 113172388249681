import * as action_types from "./constants";

export type MyCompanyState = {
  jobsList: {
    loading: boolean;
    listData: [];
  };
};

const initialState: MyCompanyState = {
  jobsList: {
    loading: true,
    listData: [],
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.JOBS_LIST:
      return {
        ...state,
        jobsList: { listData: data, loading: action?.data?.loading },
      };

    default:
      return state;
  }
};
