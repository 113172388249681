export enum ENVIRONMENTS {
  STAGING = "staging",
  LOCALSHEHAN = "localshehan",
  LOCALALI = "localali",
}
export const ENVIRONMENT_MODE: string = ENVIRONMENTS.STAGING;

export const BASE_URLS: {
  [key: string]: {
    REACT_APP_API_BASE_URL: string;
  };
} = {
  staging: {
    REACT_APP_API_BASE_URL: "https://www.fpbackend.darkhorsesolutions.co/",
  },
  localshehan: {
    REACT_APP_API_BASE_URL: "http://192.168.100.5:4000/",
  },
  localali: {
    REACT_APP_API_BASE_URL: "http://192.168.100.7:3000/",
  },
};

export enum UserCompanyFundingSources {
  "bootstrapped" = "Bootstrapped",
  "vc_backed" = "VC-backed / Raised capital",
}

export enum UserCompanyIndustries {
  aerospacedefense = "Aero Space Defense",
  agriculturelivestock = "Agriculture Live Stock",
  automotive = "Automotive",
  businessservices = "Business Services",
  construction = "Construction",
  consumerservices = "Consumer Services",
  educationtraining = "Education Training",
  privateequity = "Private Equity",
}

export const getInitials = (firstName: string, lastName: string) => {
  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();

  return firstInitial + lastInitial;
};
