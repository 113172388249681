import { Tabs } from "antd";
import JobTabs from "../JobTabs";
import { useState } from "react";

type TabItems = {
  label: string;
  coached_by: [];
  department: string;
  purpose: string;
  objectives: string[];
  key_results: string[];
  procedures: string[];
  workflows: string[];
  skills: string[];
  technologies: string[];
  teams: string[];
  entities: string[];
  meetings: string[];
};

export default function FlowerView() {
  const [activeTab, setActiveTab] = useState("1");
  const items: TabItems[] = [
    {
      label: "Leadership Training",
      coached_by: [],
      department: "Sales",
      purpose:
        "The purpose of the Leadership Training role is to achieve organizational growth and improved team performance by developing leadership skills, providing mentorship, conducting training programs, and fostering a culture of continuous improvement and innovation.",
      objectives: [
        "Following an extensive outline with supporting documents",
        "Engaging in role-playing exercises",
        "Offering ongoing coaching",
      ],
      key_results: ["Knowledgable and independent GM's"],
      procedures: ["Hiring", "Onboarding", "Training", "Tracking"],
      workflows: [
        "Initiation and Scope Definition Workflow",
        "Project Planning Workflow",
        "Risk Management Workflow",
        "Monitoring and Controlling Workflow",
      ],
      skills: ["GM", "AM", "OM", "All Frontline Positions "],
      technologies: [
        "Google Drive",
        "Pike13",
        "ATU",
        "HUB",
        "Brandbot",
        "MSC",
        "HR & Payroll",
        "WhenIWork",
        "GroupMe",
        "Dashboard",
      ],
      teams: ["GM All Call"],
      entities: ["Community", "Vendors"],
      meetings: ["Hiring", "OPS"],
    },
    {
      label: "LMA GM's",
      coached_by: [],
      department: "Sales",
      purpose:
        "The purpose of the LMA GM's role is to achieve business success and operational excellence by overseeing daily operations, implementing strategic initiatives, managing team performance, ensuring customer satisfaction, and driving continuous improvement.",
      objectives: [
        "Following an extensive outline with supporting documents",
        "Engaging in role-playing exercises",
        "Offering ongoing coaching",
        "Hold regular 1:1 meetings",
      ],
      key_results: [
        "Knowledgable and independent GM's",
        "Lead GM's accountable to the standards",
        "Consistency and processes followed",
        "Tenured Staff",
        "7 Promise Survey",
      ],
      procedures: ["Knowledgable and independent GM's"],
      workflows: [
        "Initiation and Scope Definition Workflow",
        "Project Planning Workflow",
        "Risk Management Workflow",
        "Monitoring and Controlling Workflow",
      ],
      skills: ["GM", "AM", "OM", "All Frontline Positions "],
      technologies: [
        "Google Drive",
        "Pike13",
        "ATU",
        "HUB",
        "Brandbot",
        "MSC",
        "HR & Payroll",
        "WhenIWork",
        "GroupMe",
      ],
      teams: ["SLT"],
      entities: [],
      meetings: ["Aquatic Call", "OPS"],
    },
    {
      label: "School Presence",
      coached_by: [],
      department: "Sales",
      purpose:
        "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
      objectives: [
        "Following an extensive outline with supporting documents",
        "Engaging in role-playing exercises",
        "Offering ongoing coaching",
        "Hold regular 1:1 meetings",
      ],
      key_results: [
        "Knowledgable and independent GM's",
        "Lead GM's accountable to the standards",
        "Consistency and processes followed",
        "Tenured Staff",
        "7 Promise Survey",
      ],
      procedures: ["Knowledgable and independent GM's"],
      workflows: [
        "Initiation and Scope Definition Workflow",
        "Project Planning Workflow",
        "Risk Management Workflow",
        "Monitoring and Controlling Workflow",
      ],
      skills: ["GM", "AM", "OM", "All Frontline Positions "],
      technologies: [
        "Google Drive",
        "Pike13",
        "ATU",
        "HUB",
        "Brandbot",
        "MSC",
        "HR & Payroll",
        "WhenIWork",
        "GroupMe",
      ],
      teams: ["SLT"],
      entities: [],
      meetings: ["Aquatic Call", "OPS"],
    },
    // {
    //   label: "Interim GM",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Continued Educations",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Cross Check/Data collection",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Hiring",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Drive Performance",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Franchise Alignment",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Schedule Management",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Special Projects/Events",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Public Relations",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "In School Communication",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Developing front line staff",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Developing front line staff",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Developing front line staff",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Developing front line staff",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
    // {
    //   label: "Developing front line staff",
    //   coached_by: [],
    //   department: "Sales",
    //   purpose:
    //     "The purpose of the School Presence role is to achieve a positive and supportive school environment by fostering student engagement, promoting school activities, building strong relationships with parents and the community, and ensuring a safe and inclusive atmosphere for all s...",
    //   objectives: [
    //     "Following an extensive outline with supporting documents",
    //     "Engaging in role-playing exercises",
    //     "Offering ongoing coaching",
    //     "Hold regular 1:1 meetings",
    //   ],
    //   key_results: [
    //     "Knowledgable and independent GM's",
    //     "Lead GM's accountable to the standards",
    //     "Consistency and processes followed",
    //     "Tenured Staff",
    //     "7 Promise Survey",
    //   ],
    //   procedures: ["Knowledgable and independent GM's"],
    //   workflows: [
    //     "Initiation and Scope Definition Workflow",
    //     "Project Planning Workflow",
    //     "Risk Management Workflow",
    //     "Monitoring and Controlling Workflow",
    //   ],
    //   skills: ["GM", "AM", "OM", "All Frontline Positions "],
    //   technologies: [
    //     "Google Drive",
    //     "Pike13",
    //     "ATU",
    //     "HUB",
    //     "Brandbot",
    //     "MSC",
    //     "HR & Payroll",
    //     "WhenIWork",
    //     "GroupMe",
    //   ],
    //   teams: ["SLT"],
    //   entities: [],
    //   meetings: ["Aquatic Call", "OPS"],
    // },
  ];

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div className="roles_inner_tab">
      <Tabs
        activeKey={activeTab}
        onChange={onTabChange}
        items={items?.map((e, i) => ({
          key: `${i + 1}`,
          label: (
            <div
              className="tab-label"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="tab-number">{i + 1}</span> {e.label}
            </div>
          ),
          children: (
            <JobTabs
              data={items}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              activeData={e}
              activeNumber={i + 1}
            />
          ),
        }))}
      />
    </div>
  );
}
