import { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Image,
  Breadcrumb,
  Tag,
  Avatar,
  Dropdown,
} from "antd";
import { CaretLeftOutlined } from "@ant-design/icons";
import DashboardTemplate from "../../../../templates/Dashboard";
import { Popup } from "../../../../atoms/Popup";
import SelectParticipants from "./Components/SelectParticipants";
import { ArrowIconRight } from "../../../../atoms/CustomIcons";
import ConfirmDelete from "../../../../atoms/ConfirmDelete";
import { Link } from "react-router-dom";

export default function JobDetail() {
  const [isParticipants, setIsParticipants] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const createJob = {
    visibility: isParticipants,
    class: "upload_photo_modal",
    content: (
      <SelectParticipants
        title="Start Flower Power session"
        onClose={() => setIsParticipants(false)}
        btnText="Start session"
      />
    ),
    width: 600,
    onCancel: () => setIsParticipants(false),
  };

  const items = [
    {
      key: "1",
      label: (
        <Button>
          <Link to="/edit-job"> Edit job </Link>
        </Button>
      ),
    },
    {
      key: "2",
      label: <Button>Add new role</Button>,
    },
    {
      key: "3",
      label: <Button>Add existing role</Button>,
    },
    {
      key: "4",
      label: <Button>View sessions details</Button>,
    },
    {
      key: "5",
      label: <Button>Export CSV</Button>,
    },
    {
      key: "6",
      label: <Button>Export PDF</Button>,
    },
    {
      key: "7",
      label: (
        <Button onClick={() => setIsDeleteModalOpen(true)}>Delete job</Button>
      ),
    },
  ];

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title={<>Confirm deletion</>}
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete"
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  return (
    <DashboardTemplate loading={false}>
      <Row className="secondary_header">
        <Col span={24}>
          <Breadcrumb
            separator={<ArrowIconRight />}
            items={[
              {
                href: "",
                title: (
                  <>
                    <CaretLeftOutlined />
                    <span>My company</span>
                  </>
                ),
              },
              {
                title: "Director of Ops",
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <div className="detail_top">
            <h2>Director of Ops</h2>
            <div className="right_top_job">
              <Tag className="icon_white_btn">
                Department: <b>Sales</b>
              </Tag>
              <Tag
                className="icon_white_btn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                Job holders:{" "}
                <Avatar.Group
                  className="avatarGroup"
                  size="small"
                  max={{
                    count: 3,
                    style: {
                      color: "#161616",
                      backgroundColor: "#fff",
                      cursor: "pointer",
                      fontSize: "10px",
                      fontWeight: "500",
                    },
                  }}
                >
                  <Avatar>AR</Avatar>
                  <Avatar src="/images/avatar_1.jpg" />
                  <Avatar>SN</Avatar>
                </Avatar.Group>
              </Tag>
              <div className="detail_dropdown">
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="actionButton"
                  overlayClassName="full_width_btn"
                >
                  <Button>
                    <Image
                      src="/images/vertical_dots.svg"
                      width={3}
                      height={15}
                      alt="vertical dots"
                      preview={false}
                    />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Card className="job_details">
            <h3>Job detail summary</h3>
            <Button
              className="start_session"
              onClick={() => setIsParticipants(true)}
            >
              <Image
                src="/images/empty/flower-icon.svg"
                preview={false}
                width={28}
              />
              Start Flower Power session
            </Button>
          </Card>
        </Col>
      </Row>
      <Popup {...createJob} />
      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
