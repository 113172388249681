import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const EmployessSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5096 4.64849C12.5096 6.31804 11.1562 7.67148 9.48662 7.67148C7.81707 7.67148 6.46362 6.31804 6.46362 4.64849C6.46362 2.97893 7.81707 1.62549 9.48662 1.62549C11.1562 1.62549 12.5096 2.97893 12.5096 4.64849Z"
      fill="#9396A6"
    />
    <path
      d="M15.4324 14.869C15.4324 11.9738 13.5576 8.49951 9.43237 8.49951C5.30712 8.49951 3.43237 11.9541 3.43237 14.869C3.43237 15.7005 4.10641 16.3745 4.93789 16.3745H13.9269C14.7583 16.3745 15.4324 15.7005 15.4324 14.869Z"
      fill="#9396A6"
    />
  </svg>
);

const EmployessIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EmployessSvg} {...props} />
);

export default EmployessIcon;
