import { Row, Col, Button, Card, Form, Select, Checkbox, Image } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon, DropdownIcon } from "../../../../atoms/CustomIcons";
import TextEditor from "../../../../molecules/FormItems/TextEditor";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
};

export default function AddRoles() {
  const navigate = useNavigate();
  const { control } = useForm<FormValues>();

  const optionsForDropdown = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Create Role</h3>
        </div>
      </Card>
      <Card
        className="fp_layout_content"
        style={{ height: "100%", maxWidth: "992px", margin: "0 auto" }}
      >
        <div
          className="cardContent"
          style={{
            maxWidth: "992px",
            paddingLeft: "68px",
            paddingRight: "68px",
            border: "unset",
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <Form>
            <Row>
              <Col flex="0 0 992px">
                <div className="mainTitle">
                  <h3> Customer manager </h3>
                </div>
                <h4>Job</h4>
                <Select
                  placeholder="Select one or multiple jobs"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                  showSearch
                  mode="multiple"
                ></Select>
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Role purpose statement <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4 style={{ display: "flex", gap: "6px" }}>
                      <Image
                        src="/images/MagicWand.svg"
                        alt="Generate with AI"
                        width={16}
                        height={16}
                        preview={false}
                      />
                      Generate with AI
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldname="role_purpose"
                  control={control}
                  initValue=""
                  // label="Role purpose statement"
                  // tooltipTitle=""
                  iProps={{
                    placeholder: "Explain the Role purpose statement",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Objectives <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldname="objectives"
                  control={control}
                  initValue=""
                  // label="Objectives"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Increase market share by developing and implementing effective sales strategies and fostering strong customer relationships.",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Key Results <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <Select
                  placeholder="Select key results"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                  showSearch
                  mode="multiple"
                ></Select>
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Procedures/WIs/Policies <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <TextEditor
                  fieldname="procedures"
                  control={control}
                  initValue=""
                  // label="Procedures"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Develop detailed project schedules with tasks, Establish milestones and deliverable dates, Allocate necessary resources.",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Workflows <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <Select
                  placeholder="Select workflows"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                  showSearch
                  mode="multiple"
                ></Select>
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Skills <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <Select
                  placeholder="Select skills"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                  showSearch
                  mode="multiple"
                ></Select>
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Technologies <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <Select
                  placeholder="Select Technologies"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                  showSearch
                  mode="multiple"
                ></Select>
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Teams <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <Select
                  placeholder="Select teams"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                  showSearch
                  mode="multiple"
                ></Select>
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Entities <span className="red_color">*</span>
                  </div>
                  <h4>
                    <Checkbox style={{ marginRight: "4px" }} /> N/A
                  </h4>
                </h4>
                <TextEditor
                  fieldname="Entities"
                  control={control}
                  initValue=""
                  // label="Entities"
                  // tooltipTitle=""
                  iProps={{
                    placeholder:
                      "Example: Parents, Vendors, Hiring Coordinator",
                  }}
                  isRequired
                  rules={{
                    required: "Error message",
                  }}
                />
              </Col>
              <Col flex="0 0 992px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Meetings <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <Select
                  placeholder="Select meetings"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                  showSearch
                  mode="multiple"
                ></Select>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Col flex="0 0 395px">
                <h4
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    Coached by <span className="red_color">*</span>
                  </div>
                  <div>
                    <h4>
                      <Checkbox style={{ marginRight: "4px" }} /> N/A
                    </h4>
                  </div>
                </h4>
                <Select
                  placeholder="Select role"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                ></Select>
              </Col>
              <Col flex="0 0 395px">
                <h4>
                  Department <span className="red_color">*</span>
                </h4>
                <Select
                  placeholder="Select department"
                  suffixIcon={<DropdownIcon />}
                  popupMatchSelectWidth={false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  maxTagCount="responsive"
                  options={optionsForDropdown}
                  className="fp_select"
                ></Select>
              </Col>
              <Col
                className="fp_footer"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="icon_dark_btn"
                  >
                    Create Role
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
