import { Form, Select, Button, Row, Col } from "antd";
import { useForm } from "react-hook-form";
import { DropdownIcon } from "../../../atoms/CustomIcons";

interface FormValues {
  select_role: string;
}

export default function EditJobForm() {
  const { handleSubmit } = useForm<FormValues>();
  const onSubmit = (val: FormValues) => {
    console.log("Value:", val);
  };

  const optionsForDropdown = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <Row>
        <Col span={24}>
          <h4>Job holders</h4>
          <Select
            placeholder="Job holders"
            suffixIcon={<DropdownIcon />}
            popupMatchSelectWidth={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            maxTagCount="responsive"
            options={optionsForDropdown}
            className="fp_select"
            showSearch
            mode="multiple"
          ></Select>
        </Col>
        <Col span={24}>
          <h4>Department</h4>
          <Select
            placeholder="Department"
            suffixIcon={<DropdownIcon />}
            popupMatchSelectWidth={false}
            getPopupContainer={(trigger) => trigger.parentNode}
            maxTagCount="responsive"
            options={optionsForDropdown}
            className="fp_select"
            showSearch
          ></Select>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
