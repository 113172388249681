import { Card, Button, Row, Col, Alert } from "antd";
import { rectSortingStrategy } from "@dnd-kit/sortable";
import { MultipleContainers } from "../Components/MultipleContainers";
import Draggable from "./Draggable";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

export default function Step3(props: PropsType) {
  const { setCurrent } = props;
  const data: any = [
    { id: "1", name: "a-11" },
    { id: "2", name: "a-21" },
    { id: "3", name: "a-31" },
    { id: "4", name: "a-41" },
  ];
  return (
    <Row>
      <Col span={9}>
        <Card className="fp_layout_content link_card">
          <div className="copy_link_content">
            <Alert
              message="A link to reset your password has been emailed to you."
              // icon={<SuccessIcon />}
              type="warning"
              showIcon
            />
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(0)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(1)}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={15}>
        {data.map((e: any) => (
          <Draggable key={e?.id} id={e?.id}>
            <div>{`Item ${e?.name}`}</div>
          </Draggable>
        ))}
        <MultipleContainers
          itemCount={5}
          data={data}
          strategy={rectSortingStrategy}
          vertical
        />
      </Col>
    </Row>
  );
}
