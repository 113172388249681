import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const Leaf4 = () => (
  <svg
    width="272"
    height="308"
    viewBox="0 0 272 308"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6916 188.795L136.168 306L253.362 188.845C276.627 165.588 276.828 127.936 253.812 104.432L164.479 13.2053C148.785 -2.82099 122.983 -2.81212 107.301 13.225L18.171 104.37C-4.83315 127.894 -4.6009 165.556 18.6916 188.795Z"
      fill="currentColor"
      stroke="#ECEDF3"
      stroke-width="1.62816"
    />
  </svg>
);

const LeafIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Leaf4} {...props} />
);

export default LeafIcon;
