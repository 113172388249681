import { useState } from "react";
import { Card, Button, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import FullPageLayout from "../../../../../../templates/FullPageLayout";
import { CloseIcon } from "../../../../../../atoms/CustomIcons";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

export default function SessionSteps() {
  const navigate = useNavigate();

  const [current, setCurrent] = useState(2);

  const steps = [
    {
      title: "Step1",
      content: <Step1 setCurrent={setCurrent} />,
    },
    {
      title: "Step2",
      content: <Step2 setCurrent={setCurrent} />,
    },
    {
      title: "Step3",
      content: <Step3 setCurrent={setCurrent} />,
    },
  ];

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title session_title">
        <div className="mainTitle">
          <div className="session_head_left">
            <Button
              className="icon_grey_btn"
              icon={<CloseIcon />}
              onClick={() => navigate(-1)}
            >
              End
            </Button>
            <h3>Flower Power Session</h3>
            <div className="session_avatar">
              <Avatar.Group
                className="avatarGroup"
                size="small"
                max={{
                  count: 3,
                  style: {
                    color: "#161616",
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    fontSize: "10px",
                    fontWeight: "500",
                  },
                }}
              >
                <Avatar>AR</Avatar>
                <Avatar src="/images/avatar_1.jpg" />
                <Avatar>SN</Avatar>
              </Avatar.Group>
            </div>
          </div>
          <div className="session_right_head">
            <Button className="icon_grey_btn" onClick={() => navigate(-1)}>
              Close & suspend
            </Button>
          </div>
        </div>
      </Card>
      <div className="centered_content">{steps[current].content}</div>
    </FullPageLayout>
  );
}
