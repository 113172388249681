import { useState } from "react";
import { Card, Button, Avatar, Image, Radio, RadioChangeEvent } from "antd";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

export default function Step1(props: PropsType) {
  const { setCurrent } = props;
  const [participantId, setParticipantId] = useState("");

  const onChange = (e: RadioChangeEvent) => {
    setParticipantId(e.target.value);
  };

  return (
    <Card className="fp_layout_content session_card">
      <div className="cardContent">
        <div className="select_individual_head">
          <div className="individual_left">
            <h4>
              Select one individual from the participants <span>*</span>
            </h4>
            <p>Decide who you put in the center of the flower.</p>
          </div>
          <div className="individual_right">
            <Button className="icon_grey_btn">
              <Image src="/images/session/magic-icon.svg" preview={false} />
              Select randomly
            </Button>
          </div>
        </div>

        <div className="participants_card">
          <Radio.Group buttonStyle="solid" onChange={onChange}>
            <Radio.Button value="a">
              <div className="participant_avatar">
                <Avatar src="/images/avatar_1.jpg" />
              </div>
              <div className="participant_info">
                <h6>Cheryl Lynch</h6>
                <p>Director of Ops</p>
              </div>
              <div className="prticipant_check">
                <Image
                  src="/images/session/checked-icon.svg"
                  preview={false}
                  width={18}
                  height={18}
                />
              </div>
            </Radio.Button>
            <Radio.Button value="b">
              <div className="participant_avatar">
                <Avatar>AF</Avatar>
              </div>
              <div className="participant_info">
                <h6>Albert Flores </h6>
                <p>Director of Ops</p>
              </div>
              <div className="prticipant_check">
                <Image
                  src="/images/session/checked-icon.svg"
                  preview={false}
                  width={18}
                  height={18}
                />
              </div>
            </Radio.Button>
            <Radio.Button value="c">
              <div className="participant_avatar">
                <Avatar src="/images/avatar_1.jpg" />
              </div>
              <div className="participant_info">
                <h6>Mira Carder</h6>
                <p>Director of Ops</p>
              </div>
              <div className="prticipant_check">
                <Image
                  src="/images/session/checked-icon.svg"
                  preview={false}
                  width={18}
                  height={18}
                />
              </div>
            </Radio.Button>
            <Radio.Button value="d">
              <div className="participant_avatar">
                <Avatar src="/images/avatar_1.jpg" />
              </div>
              <div className="participant_info">
                <h6>Brooklyn Simmons</h6>
                <p>Director of Ops</p>
              </div>
              <div className="prticipant_check">
                <Image
                  src="/images/session/checked-icon.svg"
                  preview={false}
                  width={18}
                  height={18}
                />
              </div>
            </Radio.Button>
            <Radio.Button value="e">
              <div className="participant_avatar">
                <Avatar>CW</Avatar>
              </div>
              <div className="participant_info">
                <h6>Cameron Williamson</h6>
                <p>Director of Ops</p>
              </div>
              <div className="prticipant_check">
                <Image
                  src="/images/session/checked-icon.svg"
                  preview={false}
                  width={18}
                  height={18}
                />
              </div>
            </Radio.Button>
          </Radio.Group>
          <Button
            htmlType="button"
            className="icon_dark_btn"
            type="primary"
            size="large"
            onClick={() => setCurrent(1)}
            disabled={!participantId}
          >
            Continue
          </Button>
        </div>
      </div>
    </Card>
  );
}
