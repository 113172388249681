import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const DragSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 11.7714C8.75 12.3237 9.19771 12.7714 9.75 12.7714C10.3023 12.7714 10.75 12.3237 10.75 11.7714C10.75 11.2191 10.3023 10.7714 9.75 10.7714C9.19771 10.7714 8.75 11.2191 8.75 11.7714Z"
      fill="#9396A6"
    />
    <path
      d="M8.75 7.9999C8.75 8.55219 9.19772 8.9999 9.75 8.9999C10.3023 8.9999 10.75 8.55219 10.75 7.9999C10.75 7.44762 10.3023 6.9999 9.75 6.9999C9.19772 6.9999 8.75 7.44762 8.75 7.9999Z"
      fill="#9396A6"
    />
    <path
      d="M8.75 4.22852C8.75 4.7808 9.19772 5.22852 9.75 5.22852C10.3023 5.22852 10.75 4.7808 10.75 4.22852C10.75 3.67623 10.3023 3.22852 9.75 3.22852C9.19772 3.22852 8.75 3.67623 8.75 4.22852Z"
      fill="#9396A6"
    />
    <path
      d="M5.25 11.7714C5.25 12.3237 5.69771 12.7714 6.25 12.7714C6.80228 12.7714 7.25 12.3237 7.25 11.7714C7.25 11.2191 6.80228 10.7714 6.25 10.7714C5.69771 10.7714 5.25 11.2191 5.25 11.7714Z"
      fill="#9396A6"
    />
    <path
      d="M5.25 7.9999C5.25 8.55219 5.69772 8.9999 6.25 8.9999C6.80228 8.9999 7.25 8.55219 7.25 7.9999C7.25 7.44762 6.80228 6.9999 6.25 6.9999C5.69772 6.9999 5.25 7.44762 5.25 7.9999Z"
      fill="#9396A6"
    />
    <path
      d="M5.25 4.22852C5.25 4.7808 5.69772 5.22852 6.25 5.22852C6.80228 5.22852 7.25 4.7808 7.25 4.22852C7.25 3.67623 6.80228 3.22852 6.25 3.22852C5.69772 3.22852 5.25 3.67623 5.25 4.22852Z"
      fill="#9396A6"
    />
  </svg>
);

const DragIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DragSvg} {...props} />
);

export default DragIcon;
