export default function Meetings() {
  return (
    <>
      <h2 className="wg_title">
        <img src="/images/question_icon.svg" />
        Meetings
      </h2>
      <p style={{ marginBottom: "12px" }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>

      <div className="guide_sections">
        <div className="guide_card blueBorder">
          <h6> Template </h6>
          <p>
            The purpose of Role X is to drive ... (explain the why). Role X goes
            about doing this by ... (explain the how). Success can be measured
            weekly by tracking these numbers... (explain the what, the goal
            line).
          </p>
        </div>
        <div className="guide_card greenBorder">
          <ul>
            <li>
              Identify the main goals your organization or team is striving to
              achieve.
            </li>
            <li> Make sure the objective is clear and concise. </li>
            <li>
              Link the objective to any relevant product info, marketing
              materials, or sales targets.
            </li>
            <li>
              There can be master objectives and sub-objectives supporting them.
            </li>
          </ul>
        </div>
        <div className="guide_card orangeBorder">
          <ul>
            <li> Make sure the objective is clear and concise. </li>
            <li>
              There can be master objectives and sub-objectives supporting them.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
