import React from "react";
import { Button, Empty } from "antd";
import { PlusIcon } from "../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";

interface EmptyCardProps {
  title: string;
  description: string;
  buttonText: string;
  navigateTo: string;
  showOnlyHeading?: boolean;
  image: JSX.Element | string;
}

const EmptyCard: React.FC<EmptyCardProps> = ({
  title,
  description,
  buttonText,
  navigateTo,
  showOnlyHeading,
  image,
}) => {
  const navigate = useNavigate();
  return (
    <div className="empty_card">
      <Empty
        image={image}
        imageStyle={{ height: 40 }}
        description={
          <>
            <h4>{title}</h4>
            {!showOnlyHeading && <p>{description}</p>}
          </>
        }
      >
        {!showOnlyHeading && (
          <Button
            className="icon_dark_btn"
            icon={<PlusIcon />}
            onClick={() => navigate(navigateTo)}
          >
            {buttonText}
          </Button>
        )}
      </Empty>
    </div>
  );
};

export default EmptyCard;
