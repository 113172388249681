import { Button, Image, Form } from "antd";
import { useForm } from "react-hook-form";
import SelectField from "../../molecules/FormItems/SelectField";

interface FormValues {
  select_role: string;
}

export default (props: any) => {
  const { onClose, title, heading, btnText, loading } = props;

  const roles = [
    { value: "1", label: "LMS GM's" },
    { value: "2", label: "School Presence" },
    { value: "3", label: "Interim GM" },
    { value: "4", label: "Continued Educations" },
    { value: "5", label: "Cross Check/Data collec..." },
    { value: "6", label: "Hiring" },
    { value: "7", label: "Drive Performance" },
    { value: "8", label: "Special Projects/Events" },
    { value: "9", label: "Public Relations" },
  ];

  const { control, handleSubmit } = useForm<FormValues>();

  const onSubmit = (val: FormValues) => {
    console.log("Value:", val);
  };

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <div className="eTableModal">
      <div className="modal_header">
        <div className="title"> {title} </div>
        <div className="close_icon">
          <Button
            size="large"
            type="link"
            className="p-0 m-20 close_popup"
            onClick={onCloseHandler}
          >
            <Image src="/images/close-icon.svg" preview={false} width={10} />
          </Button>
        </div>
      </div>

      <div className="modal_content">
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <h4 style={{ marginBottom: "16px" }}>{heading}</h4>
          <div style={{ marginBottom: "24px" }}>
            <SelectField
              fieldname="select_role"
              label="Roles"
              control={control}
              selectOption={roles}
              isRequired={false}
              className="fp_select"
              iProps={{ placeholder: "Select roles" }}
            />
          </div>
        </Form>
      </div>

      <div className="modal_footer">
        <Button
          htmlType="button"
          className="cancelBtn defaultBtnColor"
          type="default"
          size="large"
          onClick={onCloseHandler}
        >
          Cancel
        </Button>

        <Button
          htmlType="button"
          className="actionBtn dangerBtnColor"
          type="primary"
          size="large"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
};
