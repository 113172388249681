import { Row, Col, Button, Card, Form, Input } from "antd";
import { useForm } from "react-hook-form";
import InputPassword from "../../molecules/FormItems/InputPassword";
import { useAppSelector } from "../../config/hooks";
import { useState } from "react";
import { changePassword } from "./ducks/services";
import {
  CloseIcon,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "../../atoms/CustomIcons";
import SuccessMessage from "../../atoms/Toasts/SuccessMessage";
import ErrorMessage from "../../atoms/Toasts/ErrorMessage";
import { AxiosError } from "axios";
import FullPageLayout from "../../templates/FullPageLayout";
import { useNavigate } from "react-router-dom";

interface FormValues {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

export default function ChangePassword() {
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = (val: {
    current_password: string;
    new_password: string;
  }) => {
    setLoading(true);
    const payload = {
      userId: accountData?.id,
      email: accountData?.email,
      oldPassword: val?.current_password,
      newPassword: newPassword,
    };

    changePassword(payload)
      .then(() => {
        setLoading(false);
        SuccessMessage("Password changed.");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  return (
    <FullPageLayout loading={loading}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Change password</h3>
        </div>
      </Card>
      <Card className="fp_layout_content">
        <div className="cardContent">
          <Form onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 533px">
                    <h4 className="password_label">
                      Current password <span className="red_color">*</span>
                    </h4>
                    <InputPassword
                      fieldname="current_password"
                      label=""
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Enter current password",
                        autoComplete: "new-password",
                      }}
                      isRequired
                      rules={{
                        required: "Current password is required.",
                      }}
                      validate={errors.current_password && "error"}
                      validMessage={
                        errors.current_password &&
                        errors.current_password.message
                      }
                    />
                  </Col>
                  <Col flex="0 0 533px">
                    <h4 className="password_label">
                      New password <span className="red_color">*</span>
                    </h4>
                    <p style={{ marginBottom: "8px" }}>
                      {" "}
                      The password must be a minimum of 8 characters.{" "}
                    </p>
                    <Form.Item
                      label=""
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "New password is required.",
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder="Enter new password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="formControl"
                        iconRender={(visible: boolean) =>
                          visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="confirmPassword"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Confirm new password is required.",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Passwords don't match")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirm new password"
                        className="formControl"
                        iconRender={(visible: boolean) =>
                          visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col className="fp_footer">
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                      >
                        Change password
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
