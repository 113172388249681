import { combineReducers } from "@reduxjs/toolkit";
import account from "../organisms/MyAccount/ducks/reducers";
import companyDetails from "../organisms/CompanyDetails/ducks/reducers";
import settings from "../organisms/CompanySettings/ducks/reducers";
import myCompany from "../organisms/MyCompany/ducks/reducers";

const rootReducer = combineReducers({
  companyDetails,
  account,
  settings,
  myCompany,
});

export default rootReducer;
