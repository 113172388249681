import { useNavigate } from "react-router-dom";
import DashboardTemplate from "../../templates/Dashboard";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { useEffect, useState } from "react";
import { getUserDetails } from "../MyAccount/ducks/actions";

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const [loading, setLoading] = useState(false);

  console.log("accountData", accountData);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (
      accountData &&
      Object.keys(accountData)?.length > 0 &&
      accountData?.type === "member"
    ) {
      setLoading(false);
      navigate("/no-permission");
    } else if (
      Object.keys(accountData)?.length > 0 &&
      accountData?.type !== "member"
    ) {
      setLoading(false);
      navigate("/company-detail");
    } else {
      setLoading(false);
    }
  }, [accountData]);

  return <DashboardTemplate loading={loading} />;
}
