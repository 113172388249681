import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ToggleDownSvg = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8047 0.943309C12.0651 1.20366 12.0651 1.62577 11.8047 1.88612L6.76424 6.92661C6.66686 7.02784 6.5503 7.10874 6.42132 7.16457C6.28832 7.22215 6.14493 7.25186 6 7.25186C5.85507 7.25186 5.71168 7.22215 5.57868 7.16457C5.4497 7.10874 5.33314 7.02784 5.23576 6.92661L0.195262 1.88612C-0.0650873 1.62577 -0.0650873 1.20366 0.195262 0.943309C0.455611 0.682959 0.877722 0.682959 1.13807 0.943309L6 5.80524L10.8619 0.943309C11.1223 0.682959 11.5444 0.682959 11.8047 0.943309Z"
      fill="#161616"
    />
  </svg>
);

const ToggleDownIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ToggleDownSvg} {...props} />
);

export default ToggleDownIcon;
