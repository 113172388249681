import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Button,
  Select,
  Image,
  Avatar,
  Tag,
  Badge,
  Dropdown,
} from "antd";
import { useNavigate } from "react-router-dom";
import DashboardTemplate from "../../../templates/Dashboard";
import {
  ArrowIcon,
  DropdownIcon,
  EmployessIcon,
  JobsIcon,
  RolesIcon,
  SortIcon,
} from "../../../atoms/CustomIcons";
import EmptyCard from "../../../molecules/EmptyCard";
import { useAppSelector, useAppDispatch } from "../../../config/hooks";
import Meta from "antd/es/card/Meta";
import EditIcon from "../../../atoms/CustomIcons/EditIcon";
import { Popup } from "../../../atoms/Popup";
import CreateJob from "../../../atoms/CreateJob";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { getEmployeesList, getUserFilters } from "../ducks/actions";
import { getInitials } from "../../../config/constants";

interface DataType {
  key: React.Key;
  employee_name: { text: string; image: string };
  job: { name: string; roles: { name: string }[] };
  roles: string[];
  type: string;
  status: string;
  first_name: string;
  last_name: string;
  image: { id: string; name: string; url: string };
}

export default function Employees() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFilters = { status: "" };
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [jobCreate, setJobCreate] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const employeeFiltersOptions = useAppSelector(
    (state) => state?.companyDetails?.employeeFiltersOptions
  );
  const employeesListData = useAppSelector(
    (state) => state?.companyDetails?.employeesList
  );

  const items = [
    {
      key: "1",
      label: (
        <Button className="noBorder" style={{ padding: "0px" }}>
          Edit
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const categories = [
    {
      key: "1",
      label: (
        <Button onClick={() => setJobCreate(true)} className="noBorder">
          <JobsIcon className="darkIcon" />
          Job
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button onClick={() => navigate("/create-role")} className="noBorder">
          <RolesIcon className="darkIcon" />
          Role
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button onClick={() => navigate("/add-employees")} className="noBorder">
          <EmployessIcon className="darkIcon" />
          Employee
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Employee Name",
      dataIndex: "first_name",
      render: (text, record) => (
        <div className="table_with_image">
          {record?.image ? (
            <Image
              src={record?.image?.url}
              width={28}
              height={28}
              alt="Name"
              preview={false}
              style={{ borderRadius: "100px" }}
            />
          ) : (
            <Avatar>{getInitials(text, record?.last_name)}</Avatar>
          )}
          <div className="table_text">
            <span className="main_text">{`${text ?? ""} ${
              record?.last_name ?? ""
            }`}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Job",
      dataIndex: "job",
      sortIcon: () => <SortIcon />,
      render: (text) => (text?.name ? text?.name : "-"),
    },
    {
      title: "Roles",
      dataIndex: "job",
      // sorter: (a, b) => a?.roles?.localeCompare(b?.roles),
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.roles?.length > 0
          ? text?.roles?.map((e: { name: string; id: string }) => (
              <Fragment key={e?.id}>
                <Tag
                  className="defaultTag white_tag"
                  style={{ marginRight: "2px", marginLeft: "2px" }}
                >
                  {e?.name}
                </Tag>
              </Fragment>
            ))
          : "-",
    },
    {
      title: "User type",
      dataIndex: "type",
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text) => {
        let clname = "";
        if (text?.includes("Active")) {
          clname = "green_tag";
        } else if (text?.includes("Deactivated")) {
          clname = "grey_tag";
        } else {
          clname = "defaultTag";
        }
        return (
          <Tag className={`defaultTag ${clname}`}>
            {" "}
            <Badge className={`default_badge`} /> {text}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: () => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button>
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const createJob = {
    visibility: jobCreate,
    class: "upload_photo_modal",
    content: (
      <CreateJob
        title={<>Create Job</>}
        onClose={() => setJobCreate(false)}
        btnText="Create Job"
      />
    ),
    width: 600,
    onCancel: () => setJobCreate(false),
  };

  const handleDeleteJob = async () => {
    setDeleteLoading(true);
    setTimeout(() => {
      setIsDeleteModalOpen(false);
      setDeleteLoading(false);
    }, 1000);
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm Deletion"
        heading="Are you sure you want to delete this employee?"
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete employee"
        btnApi={handleDeleteJob}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    if (accountData?.company_id) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: accountData?.company_id,
        status: undefined,
        roles: undefined,
        departments: undefined,
        employees: undefined,
      };
      dispatch(getEmployeesList(payload));
    }
  }, [pagination, limit, dispatch, accountData]);

  useEffect(() => {
    if (accountData?.company_id) {
      dispatch(getUserFilters(accountData?.company_id));
    }
  }, [dispatch, accountData?.company_id]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  return (
    <DashboardTemplate loading={employeesListData?.loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="fp_main_details">
            <div className="companyInfo">
              <Meta
                avatar={
                  <Image
                    src="/images/login/company_1.png"
                    width={68}
                    height={68}
                    preview={false}
                  />
                }
                title="Navistar International"
                description="Raleigh, NC"
                className="headingH2"
              />
            </div>
            <div className="actionBtn">
              <Dropdown menu={{ items: categories }} placement="bottomLeft">
                <Button className="icon_dark_btn">
                  Create
                  <ArrowIcon className="angleIcon" />
                </Button>
              </Dropdown>
              <Button
                className="icon_grey_btn"
                icon={<EditIcon />}
                onClick={() => navigate("/company-settings/123")}
              >
                Edit company info
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <div className="company_actions">
                  <Button
                    className="icon_white_btn"
                    icon={<JobsIcon />}
                    onClick={() => navigate("/company-detail")}
                  >
                    Jobs
                  </Button>
                  <Button
                    className="icon_white_btn"
                    icon={<RolesIcon />}
                    onClick={() => navigate("/company-detail/roles")}
                  >
                    Roles
                  </Button>
                  <Button
                    className="icon_dark_btn"
                    icon={<EmployessIcon />}
                    onClick={() => navigate("/company-detail/employees")}
                  >
                    Employees
                  </Button>
                </div>
                <div className="filter_right">
                  <div
                    className="filter_btn"
                    style={{ display: "flex", gap: "6px" }}
                  >
                    <span className="filterTilte"> Filter: </span>

                    <Select
                      placeholder="Job"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={employeeFiltersOptions?.job}
                    ></Select>
                    <Select
                      placeholder="Roles"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={employeeFiltersOptions?.role}
                    ></Select>
                    <Select
                      placeholder="User type"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={employeeFiltersOptions?.type}
                    ></Select>
                    <Select
                      placeholder="Status"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={employeeFiltersOptions?.status}
                    ></Select>
                    <Image src="/images/filter_btn.svg" preview={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {employeesListData?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={employeesListData?.data}
              pagination={{
                defaultPageSize: limit,
                total: employeesListData?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
            />
          )}

          {employeesListData?.data?.length === 0 && (
            <EmptyCard
              image="/images/members/empty-card-icon.svg"
              title={
                selectedFilters?.status === "deactivated"
                  ? "There are no deactivated members."
                  : "There are no active members."
              }
              description='Click the "Add member" button to add a new member.'
              buttonText="Add member"
              navigateTo="/create-member"
              showOnlyHeading={selectedFilters?.status === "deactivated"}
            />
          )}
        </Col>
      </Row>

      <Popup {...createJob} />
      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
