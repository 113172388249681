import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";
import { JobList } from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const getJobsList = (payload: JobList) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}jobs`, payload);
      dispatch({
        type: action_types.JOBS_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.JOBS_LIST,
        data: { loading: false },
      });
    }
  };
};

export const getRolesList = (payload: JobList) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}roles`, payload);
      dispatch({
        type: action_types.ROLES_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.ROLES_LIST,
        data: { loading: false },
      });
    }
  };
};

export const getEmployeesList = (payload: JobList) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}users`, payload);
      dispatch({
        type: action_types.EMPLOYEES_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.EMPLOYEES_LIST,
        data: { loading: false },
      });
    }
  };
};

export const getJobFilters = (companyId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getJobFilters/${companyId}`);
    dispatch({
      type: action_types.JOB_FILTERS,
      data,
    });
  };
};

export const getRolesFilters = (companyId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getRolesFilters/${companyId}`);
    dispatch({
      type: action_types.ROLES_FILTERS,
      data,
    });
  };
};

export const getUserFilters = (companyId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getUserFilters/${companyId}`);
    dispatch({
      type: action_types.EMPLOYEES_FILTERS,
      data,
    });
  };
};

export const setFiltersOptionsJobs = (
  type: string,
  value: string | undefined
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS_JOBS,
      payload: { jobFilters: { [type]: value } },
    });
  };
};
