import { Button, Col, Image, Row, Select } from "antd";
import InputField from "../../molecules/FormItems/InputField";
import { useForm } from "react-hook-form";
import { DropdownIcon } from "../CustomIcons";
import SelectField from "../../molecules/FormItems/SelectField";

export default (props: any) => {
  const { onClose, title, btnText, btnApi } = props;

  const onCloseHandler = () => {
    onClose();
  };

  type FormValues = {
    job_name: string;
    last_name: string;
    email: string;
  };

  const optionsForDropdown = [
    { value: "Option 1", label: "Option 1" },
    { value: "Option 2", label: "Option 2" },
    { value: "Option 3", label: "Option 3" },
  ];

  const jobHolders = [
    { label: "Host 1", value: "1", avatar: "/images/avatar_1.svg" },
    { label: "Host 2", value: "2", avatar: "/images/avatar_2.svg" },
    { label: "Host 3", value: "3", avatar: "/images/avatar_1.svg" },
  ];

  const {
    control,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    // <Spin indicator={antIcon} size="large" spinning={loading}>
    <div className="eTableModal">
      <div className="modal_header">
        <div className="title"> {title} </div>
        <div className="close_icon">
          <Button
            size="large"
            type="link"
            className="p-0 m-20 close_popup"
            onClick={() => onCloseHandler()}
          >
            <Image src="/images/close-icon.svg" preview={false} width={10} />
          </Button>
        </div>
      </div>

      <div className="modal_content">
        <Row gutter={16}>
          <Col flex="0 0 100%">
            <h4>
              Job Name <span className="red_color">*</span>
            </h4>
            <InputField
              fieldname="job_name"
              label=""
              control={control}
              initValue=""
              iProps={{
                placeholder: "Type name",
              }}
              isRequired
              rules={{
                required: "Job name is required.",
              }}
              validate={errors.job_name && "error"}
              validMessage={errors.job_name && errors.job_name.message}
            />
          </Col>
          <Col flex="0 0 100%">
            <h4>Job Holders</h4>
            <SelectField
              fieldname="job_holders"
              label=""
              control={control}
              selectOption={jobHolders}
              isRequired={true}
              className="fp_select"
              iProps={{ placeholder: "Select employee" }}
            />
          </Col>
          <Col flex="0 0 100%">
            <h4>Departments</h4>
            <Select
              placeholder="Select department"
              suffixIcon={<DropdownIcon />}
              popupMatchSelectWidth={false}
              getPopupContainer={(trigger) => trigger.parentNode}
              maxTagCount="responsive"
              options={optionsForDropdown}
              className="fp_select"
            ></Select>
          </Col>
        </Row>
      </div>

      <div className="modal_footer">
        <Button
          htmlType="button"
          className="cancelBtn defaultBtnColor"
          type="default"
          size="large"
          onClick={() => onCloseHandler()}
        >
          Cancel
        </Button>

        <Button
          htmlType="button"
          className="actionBtn dangerBtnColor"
          type="primary"
          size="large"
          onClick={btnApi}
        >
          {btnText}
        </Button>
      </div>
    </div>
    // </Spin>
  );
};
