import { Fragment, useEffect, useState } from "react";
import { Card, Button, Row, Col, Alert } from "antd";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "../Components/SortableItem";

type PropsType = {
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

export default function Step2(props: PropsType) {
  const { setCurrent } = props;
  // const [participantId, setParticipantId] = useState("");
  const [items, setItems] = useState<any>([]);
  // const onChange = (e: RadioChangeEvent) => {
  //   setParticipantId(e.target.value);
  // };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items: any) => {
        const oldIndex = items.findIndex(
          (record: { id: string }) => record?.id === active?.id
        );
        const newIndex = items.findIndex(
          (record: { id: string }) => record?.id === over?.id
        );

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  useEffect(() => {
    const arrayItems = [
      { id: 1, name: "1" },
      { id: 2, name: "2" },
      { id: 3, name: "3" },
    ];
    setItems(arrayItems);
  }, []);

  return (
    <Row>
      <Col span={12}>
        <Card className="fp_layout_content link_card">
          <div className="copy_link_content">
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {items?.map((e: { id: string }, ind: number) => (
                  <Fragment key={e?.id}>
                    <SortableItem key={e?.id} data={e} index={ind} />
                  </Fragment>
                ))}
              </SortableContext>
            </DndContext>

            <Alert
              message="A link to reset your password has been emailed to you."
              // icon={<SuccessIcon />}
              type="warning"
              showIcon
            />
          </div>
          <div className="steps_footer">
            <div className="steps_btn_wrapper">
              <Button
                htmlType="button"
                className="icon_grey_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(0)}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="icon_dark_btn"
                type="primary"
                size="large"
                onClick={() => setCurrent(1)}
              >
                Continue
              </Button>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={12}></Col>
    </Row>
  );
}
