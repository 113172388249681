import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import "./app/assets/scss/style.scss";
import Login from "./app/organisms/Login";
import ForgotPassword from "./app/organisms/ForgotPassword";
import UpdatePassword from "./app/organisms/UpdatePassword";
import MyAccount from "./app/organisms/MyAccount";
import { isLoggedIn } from "./app/config/utils";
import Dashboard from "./app/organisms/Dashboard";
import NoPermission from "./app/organisms/NoPermission";
import ChangePassword from "./app/organisms/ChangePassword";
import CompanySettings from "./app/organisms/CompanySettings";
import CompanyDetails from "./app/organisms/CompanyDetails/Jobs";
import MyJob from "./app/organisms/MyJob";
import Employees from "./app/organisms/CompanyDetails/Employees";
import Roles from "./app/organisms/CompanyDetails/Roles";
import AddEmployees from "./app/organisms/CompanyDetails/Employees/AddEmployees";
import AddRoles from "./app/organisms/CompanyDetails/Roles/AddRoles";
import JobDetail from "./app/organisms/CompanyDetails/Jobs/JobDetail";
import Companies from "./app/organisms/Companies";
import CreateCompany from "./app/organisms/Companies/CreateCompany";
import SystemSettings from "./app/organisms/SystemSettings";
import SessionSteps from "./app/organisms/CompanyDetails/Jobs/JobDetail/Components/SessionSteps";
import EditJob from "./app/organisms/CompanyDetails/Jobs/EditJob";

function App() {
  const auth = isLoggedIn();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "DM Sans",
        },
      }}
    >
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            {auth ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/no-permission" element={<NoPermission />} />
                <Route path="/company-detail" element={<CompanyDetails />} />
                <Route
                  path="/company-detail/employees"
                  element={<Employees />}
                />
                <Route path="/company-detail/roles" element={<Roles />} />
                <Route path="/add-employees" element={<AddEmployees />} />
                <Route path="/create-role" element={<AddRoles />} />
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route
                  path="/company-settings/:id"
                  element={<CompanySettings />}
                />
                <Route path="/my-job" element={<MyJob />} />
                <Route path="/job-detail" element={<JobDetail />} />
                <Route path="/edit-job" element={<EditJob />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/create-company" element={<CreateCompany />} />
                <Route path="/system-settings" element={<SystemSettings />} />
                <Route path="/session-steps" element={<SessionSteps />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/login" replace />} />
            )}
          </Routes>
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
