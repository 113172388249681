import { Button, Col, Form, Image, Row, Spin } from "antd";
import { useForm } from "react-hook-form";
import debounce from "lodash/debounce";
import { useMemo, useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import SelectField from "../../../../../../molecules/FormItems/SelectField";
import { SearchIcon } from "../../../../../../atoms/CustomIcons";

const antIcon = <Loading3QuartersOutlined spin />;

export const participantsDropdown = (
  query: string
): Promise<AxiosResponse<{ query: string }>> => {
  return axios.get(`https://randomuser.me/api/${query}`);
};

export default (props: any) => {
  const { onClose, title, btnText, btnApi } = props;
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const fetchRef = useRef(0);
  const fetchOptions = () => Promise<[]>;
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const onCloseHandler = () => {
    onClose();
  };

  type FormValues = {
    participants: string[];
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      if (value) {
        participantsDropdown(value).then((newOptions: AxiosResponse) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          setOptions(newOptions?.data);
          setFetching(false);
        });
      }
    };

    return debounce(loadOptions, 800);
  }, [fetchOptions, 800]);

  const { control, handleSubmit } = useForm<FormValues>();

  const onSubmit = () => {
    setLoading(true);
  };
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={() => onCloseHandler()}
            >
              <Image src="/images/close-icon.svg" preview={false} width={10} />
            </Button>
          </div>
        </div>
        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <div className="modal_content">
            <Row gutter={16}>
              <Col flex="0 0 100%">
                <SelectField
                  className="participant_select"
                  fieldname="participants"
                  label="Select participants"
                  control={control}
                  selectOption={options}
                  rules={{ required: "Employees is required" }}
                  onSelectChange={(e: { value: string }) =>
                    setCompanyID(e?.value)
                  }
                  initValue={companyID}
                  iProps={{
                    placeholder: "Search employees",
                    onSearch: debounceFetcher,
                    notFoundContent: fetching ? <Spin size="small" /> : null,
                    suffixIcon: <SearchIcon />,
                  }}
                />
              </Col>
            </Row>
          </div>

          <div className="modal_footer">
            <Button
              htmlType="button"
              className="cancelBtn defaultBtnColor"
              type="default"
              size="large"
              onClick={() => onCloseHandler()}
            >
              Cancel
            </Button>

            <Button
              htmlType="button"
              className="actionBtn dangerBtnColor"
              type="primary"
              size="large"
              onClick={btnApi}
            >
              {btnText}
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};
