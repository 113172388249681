import * as action_types from "./constants";

export type MyCompanyState = {
  jobsList: {
    loading: boolean;
    count: number;
    data: [];
  };
  rolesList: {
    loading: boolean;
    count: number;
    data: [];
  };
  employeesList: {
    loading: boolean;
    count: number;
    data: [];
  };
  jobFilters: {
    roles: undefined;
    departments: undefined;
    employees: undefined;
    status: undefined;
  };
  jobFiltersOptions: {
    roles: string;
  };
  rolesFiltersOptions: {
    job: string;
  };
  employeeFiltersOptions: {
    job: string;
  };
};

const initialState: MyCompanyState = {
  jobsList: {
    loading: true,
    count: 0,
    data: [],
  },
  rolesList: {
    loading: true,
    count: 0,
    data: [],
  },
  employeesList: {
    loading: true,
    count: 0,
    data: [],
  },
  jobFilters: {
    roles: undefined,
    departments: undefined,
    employees: undefined,
    status: undefined,
  },
  jobFiltersOptions: {
    roles: "",
  },
  rolesFiltersOptions: {
    job: "",
  },
  employeeFiltersOptions: {
    job: "",
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.JOBS_LIST:
      return {
        ...state,
        jobsList: {
          count: data?.count,
          data: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.ROLES_LIST:
      return {
        ...state,
        rolesList: {
          count: data?.count,
          data: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.EMPLOYEES_LIST:
      return {
        ...state,
        employeesList: {
          count: data?.count,
          data: data?.data,
          loading: action?.data?.loading,
        },
      };

    case action_types.JOB_FILTERS:
      return { ...state, jobFiltersOptions: data };

    case action_types.ROLES_FILTERS:
      return { ...state, rolesFiltersOptions: data };

    case action_types.EMPLOYEES_FILTERS:
      return { ...state, employeeFiltersOptions: data };

    case action_types.SET_FILTERS_OPTIONS_JOBS:
      return {
        ...state,
        filter: { ...state.jobFilters, ...action.payload.jobFilters },
      };

    default:
      return state;
  }
};
