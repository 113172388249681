import { useState } from "react";
import { Card, Button } from "antd";
import FullPageLayout from "../../../../templates/FullPageLayout";
import { CloseIcon, DragIcon, PlusIcon } from "../../../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import WorksheetGuide from "../../../../atoms/WorksheetGuide";
import { Popup } from "../../../../atoms/Popup";
import AddRole from "../../../../atoms/AddRole";
import EditJobForm from "../../../../molecules/FormItems/EditJobForm";

export default function EditJob() {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const addRolePopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <AddRole
        title="Add roles to the job"
        heading="Select roles you want to add to Director of Ops."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Add role"
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Edit Job</h3>
        </div>
      </Card>

      <div className="fp_layout_content">
        <div className="editJob">
          <div className="controlPanel">
            <div className="title">
              <h6>Roles</h6>
              <span onClick={() => setIsDeleteModalOpen(true)}>
                <PlusIcon className="PlusIcon" />
                Add role
              </span>
            </div>
            <div className="roleAdd">
              <ul>
                <li>
                  <DragIcon />
                  <span className="count">1</span> Face of School
                </li>
                <li>
                  <DragIcon />
                  <span className="count">2</span> Safety
                </li>
                <li>
                  <DragIcon />
                  <span className="count">3</span> Staff Scheduling
                </li>
                <li>
                  <DragIcon />
                  <span className="count">4</span> Payroll
                </li>
                <li>
                  <DragIcon />
                  <span className="count">5</span> Continued Educations
                </li>
                <li>
                  <DragIcon />
                  <span className="count">6</span> Cross Check/Data collec...
                </li>
                <li>
                  <DragIcon />
                  <span className="count">5</span> Hiring
                </li>
                <li>
                  <DragIcon />
                  <span className="count">6</span> Drive Performance
                </li>
              </ul>
            </div>
          </div>
          <div className="contentPanel">
            <div className="drawerButton">
              <WorksheetGuide />
            </div>
            <div className="title_bar">
              <div className="mainTitle">
                <div>
                  <img
                    src="/images/EditDarkIcon.svg"
                    style={{ marginRight: "12px" }}
                  />
                </div>
                <div>
                  <h4>Job: Director of Ops</h4>
                  <p>
                    Now, look at your job as a whole and complete the details
                    about it.
                  </p>
                </div>
              </div>
              <div className="formLink">
                Fill out from roles
                <img src="/images/Fill_out.svg" style={{ marginLeft: "6px" }} />
              </div>
            </div>
            <div>
              <EditJobForm />
            </div>
          </div>
        </div>
      </div>

      <Popup {...addRolePopup} />
    </FullPageLayout>
  );
}
