import { useState } from "react";
import {
  Row,
  Col,
  Button,
  Tag,
  Card,
  TabsProps,
  Tabs,
  Badge,
  Avatar,
} from "antd";
import DashboardTemplate from "../../templates/Dashboard";
import {
  FlowerIcon,
  TableIcon,
  ToggleDownIcon,
  ToggleUpIcon,
} from "../../atoms/CustomIcons";
import TableView from "./Components/TableView";
import FlowerView from "./Components/FlowerView";

export default function MyJob() {
  const [showFullInfo, setShowFullInfo] = useState(false);
  const [isApproved, setIsApproved] = useState(true);

  const toggleStatus = () => {
    setIsApproved(!isApproved);
  };

  // const roleArray = [
  //   {
  //     "Leadership Training": [
  //       {
  //         "Purpose statement":
  //           "The purpose of the Leadership Training role is to achieve organizational growth and improved team performance by developing leadership skills, providing mentorship, conducting training programs, and fostering a culture of continuous improvement and innovation.",
  //       },
  //       {
  //         Objectives: [
  //           "Following an extensive outline with supporting documents",
  //           "Engaging in role-playing exercises",
  //           "Offering ongoing coaching",
  //         ],
  //       },
  //     ],
  //   },
  // ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Flower view",
      children: <FlowerView />,
      icon: <TableIcon />,
    },
    {
      key: "2",
      label: "Table view",
      children: <TableView />,
      icon: <FlowerIcon />,
    },
  ];

  return (
    <DashboardTemplate loading={false}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card className="my_job">
            <div className="my_job_summary">
              <h3>Job detail summary</h3>
              <Button
                className="toggle_info"
                onClick={() => setShowFullInfo(!showFullInfo)}
              >
                {showFullInfo ? (
                  <>
                    Hide info <ToggleUpIcon />
                  </>
                ) : (
                  <>
                    View full info <ToggleDownIcon />
                  </>
                )}
              </Button>
            </div>

            <Row className="job_summary_thumbs">
              <Col flex="0 0 37%">
                <div style={{ height: "225px" }}>
                  <h4>Purpose statement</h4>
                  <p>
                    Make sure the new candidates are equipped and empowered to
                    fulfill their seat. We do this by following an extensive
                    outline with supporting documents, role-playing, working
                    alongside, providing feedback and coaching. Make sure the
                    new candidates have access to all necessary ...
                  </p>
                </div>

                {showFullInfo && (
                  <div className="show_full_info">
                    <div className="full_info_wrap">
                      <h4> Key results </h4>
                      <div className="large_tags">
                        <Tag>Knowledgable and independent GM's</Tag>
                        <Tag>
                          Lead and hold GM's accountable to the standards.{" "}
                        </Tag>
                        <Tag>Consistency and processes followed</Tag>
                        <Tag>Tenured Staff</Tag>
                        <Tag>7 Promise Survey</Tag>
                      </div>
                    </div>

                    <div className="full_info_wrap">
                      <h4>Workflows</h4>
                      <div className="large_tags">
                        <Tag>Initiation and Scope Definition Workflow</Tag>
                        <Tag>Project Planning Workflow</Tag>
                        <Tag>Risk Management Workflow</Tag>
                        <Tag>Monitoring and Controlling Workflow</Tag>
                      </div>
                    </div>

                    <div className="full_info_wrap">
                      <h4>Skills</h4>
                      <div className="large_tags">
                        <Tag>GM</Tag>
                        <Tag>AM</Tag>
                        <Tag>OM</Tag>
                        <Tag>All Frontline Positions </Tag>
                      </div>
                    </div>

                    <div className="full_info_wrap">
                      <h4>Teams</h4>
                      <div className="large_tags">
                        <Tag>SLT</Tag>
                        <Tag>In school leadership: GM,AM,OM</Tag>
                        <Tag>HQ Teams</Tag>
                        <Tag>Ops</Tag>
                        <Tag>MITS</Tag>
                        <Tag>Aquatic</Tag>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <Col flex="0 0 37%">
                <div style={{ height: "225px" }}>
                  <h4>Objectives</h4>
                  <ul>
                    <li> Monitor project progress and adjust plans; </li>
                    <li> Define project scope and objectives; </li>
                    <li>
                      {" "}
                      Identify and mitigate potential risks to project success.{" "}
                    </li>
                    <li>
                      {" "}
                      Manage project budgets and ensure cost efficiency;{" "}
                    </li>
                    <li> Ensure compliance with industry regulations; </li>
                    <li>
                      {" "}
                      Identify operational risks to ensure business continuity;{" "}
                    </li>
                    <li> Optimize processes to improve productivity; </li>
                  </ul>
                </div>

                {showFullInfo && (
                  <div className="show_full_info">
                    <div className="full_info_wrap">
                      <h4>Entities</h4>
                      <ul>
                        <li>Parents</li>
                        <li>Vendors</li>
                        <li>HQ</li>
                        <li>Hiring Coordinator</li>
                        <li>Training Coordinator</li>
                      </ul>
                    </div>

                    <div className="full_info_wrap">
                      <h4>Procedures/WIs/Policies</h4>
                      <ul>
                        <li>Hiring</li>
                        <li>Onboarding</li>
                        <li>Training</li>
                        <li>Check in</li>
                        <li>NPS</li>
                      </ul>
                    </div>

                    <div className="full_info_wrap">
                      <h4>Technologies</h4>
                      <div className="large_tags">
                        <Tag>Google Drive</Tag>
                        <Tag>Pike13</Tag>
                        <Tag>ATU</Tag>
                        <Tag>HUB</Tag>
                        <Tag>Brandbot</Tag>
                        <Tag>MSC</Tag>
                        <Tag>HR & Payroll</Tag>
                        <Tag>WhenIWork</Tag>
                        <Tag>GroupMe</Tag>
                      </div>
                    </div>

                    <div className="full_info_wrap">
                      <h4>Meetings</h4>
                      <div className="large_tags">
                        <Tag>SLT L10</Tag>
                        <Tag>GM L10</Tag>
                        <Tag>OPS</Tag>
                        <Tag>Owner Check in</Tag>
                        <Tag>HQ</Tag>
                        <Tag>Staff In-Services</Tag>
                        <Tag>In school staff meetings</Tag>
                        <Tag>Aquatic Call</Tag>
                        <Tag>Leadership Call</Tag>
                        <Tag>MIT Call</Tag>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <Col flex="0 0 23%">
                <div style={{ height: "225px" }}>
                  <h4>Status</h4>
                  <div className="status_column">
                    <Button
                      className={`current_status ${
                        isApproved ? "approved" : "completed"
                      }`}
                    >
                      {isApproved ? (
                        <Badge className="green_badge" />
                      ) : (
                        <Badge className="blue_badge" />
                      )}
                      {isApproved ? "Approved" : "Completed"}
                    </Button>
                    <Button onClick={toggleStatus} className="statusUpdateBtn">
                      {isApproved ? <ToggleDownIcon /> : <ToggleUpIcon />}
                    </Button>
                  </div>

                  <div
                    className="card_role"
                    style={{
                      width: "50%",
                      backgroundColor: "#fff",
                      border: "unset",
                      marginBottom: "28px",
                    }}
                  >
                    <p className="role_title">Reports to</p>
                    <div
                      className="role_name"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      Project owner <img src="/images/visit_icon.svg" />
                      <div>
                        <Avatar.Group
                          className="avatarGroup"
                          size="small"
                          max={{
                            count: 3,
                            style: {
                              color: "#161616",
                              backgroundColor: "#fff",
                              cursor: "pointer",
                              fontSize: "10px",
                              fontWeight: "500",
                            },
                          }}
                        >
                          <Avatar>AR</Avatar>
                          <Avatar src="/images/avatar_1.jpg" />
                          <Avatar>SN</Avatar>
                        </Avatar.Group>
                      </div>
                    </div>
                  </div>
                  {showFullInfo && (
                    <div
                      className="card_role"
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        border: "unset",
                      }}
                    >
                      <p className="role_title">Mentored by</p>
                      <div
                        className="role_name"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "8px",
                          marginBottom: "28px",
                        }}
                      >
                        <div>
                          <Avatar.Group
                            className="avatarGroup"
                            size="small"
                            max={{
                              count: 1,
                              style: {
                                color: "#161616",
                                backgroundColor: "#fff",
                                cursor: "pointer",
                                fontSize: "10px",
                                fontWeight: "500",
                              },
                            }}
                          >
                            <Avatar>MB</Avatar>
                          </Avatar.Group>
                        </div>
                        Mike B.
                        <span className="ant-tag defaultTag white_tag">
                          Project Owner
                        </span>
                      </div>
                      <p className="role_title">Last updated</p>
                      <p style={{ fontSize: "14px", fontWeight: "600" }}>
                        Jun 12, 2024
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <h2 style={{ position: "absolute", top: "12px" }}> Job roles </h2>
          <Tabs defaultActiveKey="1" items={items} className="job_roles_tabs" />
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
