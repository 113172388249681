import { Row, Col, Tabs, Card, Button } from "antd";
import type { TabsProps } from "antd";
import GeneralSettings from "./GeneralSettings";
import FullPageLayout from "../../templates/FullPageLayout";
import { CloseIcon, EditIcon } from "../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import KeyResult from "./KeyResult";
import Skills from "./Skills";
import Technologies from "./Technologies";
import Teams from "./Teams";
import Meetings from "./Meetings";
import Department from "./Department";

export default function CompanySettings() {
  const navigate = useNavigate();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General Settings",
      children: <GeneralSettings />,
      icon: <EditIcon />,
    },
    {
      key: "2",
      label: "Jobs & roles settings",
      disabled: true,
    },
    {
      key: "3",
      label: "Departments",
      children: <Department />,
    },
    {
      key: "4",
      label: "Key results",
      children: <KeyResult />,
    },
    {
      key: "5",
      label: "Skills",
      children: <Skills />,
    },
    {
      key: "6",
      label: "Technologies",
      children: <Technologies />,
    },
    {
      key: "7",
      label: "Teams",
      children: <Teams />,
    },
    {
      key: "8",
      label: "Meetings",
      children: <Meetings />,
    },
  ];

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Company settings</h3>
        </div>
      </Card>

      <div className="fp_layout_content">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="1"
              items={items}
              tabPosition="left"
              className="company_settings_tabs"
            />
          </Col>
        </Row>
      </div>
    </FullPageLayout>
  );
}
