import * as action_types from "./constants";

export type membersState = {
  filter: {
    status: undefined;
    search: undefined;
  };
};

const initialState: membersState = {
  filter: {
    status: undefined,
    search: undefined,
  },
};

export default (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case action_types.SET_FILTERS_OPTIONS_SYSTEM:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload.filter },
      };

    default:
      return state;
  }
};
