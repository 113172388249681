import React, { useState } from "react";
import DashboardTemplate from "../../templates/Dashboard";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Row,
  Table,
  Image,
  TableColumnsType,
  Tag,
  Badge,
} from "antd";
import { PlusIcon, SortIcon } from "../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import EmptyCard from "../../molecules/EmptyCard";
import { Popup } from "../../atoms/Popup";
import ConfirmDelete from "../../atoms/ConfirmDelete";

export default function SystemSettings() {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  interface DataType {
    key: React.Key;
    name: { text: string; image: string };
    email: string;
    access_level: string;
    status: string;
  }

  const items = [
    {
      key: "1",
      label: (
        <Button className="noBorder" style={{ padding: "0px" }}>
          Edit details
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button className="noBorder" style={{ padding: "0px" }}>
          Deactivate
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <div className="table_with_image">
          {text?.image ? (
            <Image
              src={text?.image}
              width={28}
              height={28}
              alt="Quote"
              preview={false}
              style={{ borderRadius: "100px" }}
            />
          ) : (
            <Avatar size={28} />
          )}
          <div className="table_text">
            <span className="main_text">{text?.text}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Access level",
      dataIndex: "access_level",
      sorter: (a, b) => a?.access_level?.localeCompare(b?.access_level),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text) => {
        let clname = "";
        if (text?.includes("Active")) {
          clname = "green_tag";
        } else if (text?.includes("Deactivated")) {
          clname = "grey_tag";
        } else {
          clname = "defaultTag";
        }
        return (
          <Tag className={`defaultTag ${clname}`}>
            <Badge className={`default_badge`} /> {text}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: () => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button>
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const tableData = [
    {
      key: "1",
      id: "1",
      name: {
        text: "James George",
        image: "/images/avatar_1.jpg",
      },
      email: "james.george@gmail.com",
      access_level: "Super admin",
      status: "Active",
    },
    {
      key: "2",
      id: "2",
      name: {
        text: "Mary Siphron",
        image: "/images/avatar_2.jpg",
      },
      email: "james.george@gmail.com",
      access_level: "Super admin",
      status: "Active",
    },
    {
      key: "3",
      id: "3",
      name: {
        text: "Ryan Septimus",
        image: "/images/avatar_3.jpg",
      },
      email: "james.george@gmail.com",
      access_level: "Super admin",
      status: "Deactivated",
    },
    {
      key: "4",
      id: "4",
      name: {
        text: "Sky Siphron",
        image: "/images/avatar_4.jpg",
      },
      email: "james.george@gmail.com",
      access_level: "Super admin",
      status: "Deactivated",
    },
    {
      key: "5",
      id: "5",
      name: {
        text: "Walt Brown (Me)",
        image: "/images/avatar_5.jpg",
      },
      email: "james.george@gmail.com",
      access_level: "Super admin",
      status: "Active",
    },
  ];

  const handleDeleteCompany = async () => {
    setDeleteLoading(true);
    setTimeout(() => {
      setIsDeleteModalOpen(false);
      setDeleteLoading(false);
    }, 1000);
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm deletion"
        heading="Delete James George?"
        paragraph_1="This user will be deleted. This action can't be undone."
        // paragraph_2="Confirm this operation by typing the company name below."
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete user"
        btnApi={handleDeleteCompany}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  return (
    <DashboardTemplate loading={false}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="fp_main_details">
            <div className="companyInfo">
              <h1>System settings </h1>
            </div>
            <div className="actionBtn">
              <Button
                className="icon_dark_btn"
                icon={<PlusIcon />}
                onClick={() => navigate("/create-company")}
              >
                Create user
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24}>
          {tableData.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={tableData}
              pagination={{
                defaultPageSize: 10,
                total: tableData.length,
                current: 1,
              }}
              rowKey="id"
            />
          )}

          {tableData.length === 0 && (
            <EmptyCard
              image="/images/members/empty-card-icon.svg"
              title="No companies found."
              description='Click the "Create company" button to add a new company.'
              buttonText="Add company"
              navigateTo="/create-company"
            />
          )}
        </Col>
      </Row>

      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
