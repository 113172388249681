import { useState, useEffect } from "react";
import { Button, Drawer, Image, Tabs, TabsProps } from "antd";
import PurposeStatement from "./PurposeStatement";
import Entities from "./Entities";
import KeyResults from "./KeyResults";
import Meetings from "./Meetings";
import Objectives from "./Objectives";
import ProceduresPolicies from "./ProceduresPolicies";
import Skills from "./Skills";
import Teams from "./Teams";
import Technologies from "./Technologies";
import Workflows from "./Workflows";

const WorksheetGuide = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Entities",
      children: <Entities />,
    },
    {
      key: "2",
      label: "Key Results",
      children: <KeyResults />,
    },
    {
      key: "3",
      label: "Meetings",
      children: <Meetings />,
    },
    {
      key: "4",
      label: "Objectives",
      children: <Objectives />,
    },
    {
      key: "5",
      label: "Procedures/WIs/Policies",
      children: <ProceduresPolicies />,
    },
    {
      key: "6",
      label: "Purpose Statement",
      children: <PurposeStatement />,
    },
    {
      key: "7",
      label: "Skills",
      children: <Skills />,
    },
    {
      key: "8",
      label: "Teams",
      children: <Teams />,
    },
    {
      key: "9",
      label: "Technologies",
      children: <Technologies />,
    },
    {
      key: "10",
      label: "Workflows",
      children: <Workflows />,
    },
  ];

  return (
    <>
      <Button type="primary" onClick={showDrawer} className="drawerBtn">
        <Image src="/images/guide-icon.svg" preview={false} />
      </Button>

      <Drawer
        title="Worksheet Guide"
        placement="right"
        closable={true}
        onClose={onClose}
        open={open}
        getContainer={false}
        className="guide_drawer"
      >
        <Tabs
          tabPosition="left"
          defaultActiveKey="1"
          items={items}
          className="drawer_tabs"
        />
      </Drawer>
    </>
  );
};

export default WorksheetGuide;
