import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Button,
  Select,
  Image,
  Avatar,
  Tag,
  Badge,
  Dropdown,
} from "antd";
import { useNavigate } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import DashboardTemplate from "../../../templates/Dashboard";
import {
  ArrowIcon,
  DropdownIcon,
  EmployessIcon,
  JobsIcon,
  RolesIcon,
  SortIcon,
} from "../../../atoms/CustomIcons";
import EmptyCard from "../../../molecules/EmptyCard";
import { useAppSelector, useAppDispatch } from "../../../config/hooks";
import EditIcon from "../../../atoms/CustomIcons/EditIcon";
import { Popup } from "../../../atoms/Popup";
import CreateJob from "../../../atoms/CreateJob";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { getJobFilters, getJobsList } from "../ducks/actions";
import { getInitials } from "../../../config/constants";

interface DataType {
  id: string;
  name: string;
  department_name: string;
  status: string;
  roles: { id: string; name: string }[];
  employees: string[];
}

export default function CompanyDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedFilters = { status: "" };
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [jobCreate, setJobCreate] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const jobsFilterOptions = useAppSelector(
    (state) => state?.companyDetails?.jobFiltersOptions
  );
  const jobsListData = useAppSelector(
    (state) => state?.companyDetails?.jobsList
  );

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="noBorder"
          style={{ padding: "0px" }}
          onClick={() => navigate("/job-detail")}
        >
          Edit
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          onClick={() => setIsDeleteModalOpen(true)}
          className="noBorder"
          style={{ padding: "0px" }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const categories = [
    {
      key: "1",
      label: (
        <Button onClick={() => setJobCreate(true)} className="noBorder">
          <JobsIcon className="darkIcon" />
          Job
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button onClick={() => navigate("/create-role")} className="noBorder">
          <RolesIcon className="darkIcon" />
          Role
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button onClick={() => navigate("/add-employees")} className="noBorder">
          <EmployessIcon className="darkIcon" />
          Employee
        </Button>
      ),
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Job Name",
      dataIndex: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Roles",
      dataIndex: "roles",
      sortIcon: () => <SortIcon />,
      render: (text) =>
        text?.length > 0
          ? text?.map((e: { name: string; id: string }) => (
              <Tag
                className="defaultTag white_tag"
                style={{ marginRight: "2px", marginLeft: "2px" }}
                key={e?.id}
              >
                {e?.name}
              </Tag>
            ))
          : "-",
    },
    {
      title: "Holders",
      dataIndex: "employees",
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Avatar.Group
          className="avatarGroup"
          size="small"
          max={{
            count: 2,
            style: {
              color: "#161616",
              backgroundColor: "#fff",
              cursor: "pointer",
              fontSize: "10px",
              fontWeight: "500",
            },
          }}
        >
          {text?.length > 0
            ? text?.map(
                (e: {
                  first_name: string;
                  last_name: string;
                  id: string;
                  image: { url: string };
                }) => (
                  <Fragment key={e?.id}>
                    {e?.image ? (
                      <Avatar src={e?.image?.url} />
                    ) : (
                      <Avatar>
                        {getInitials(e?.first_name, e?.last_name)}
                      </Avatar>
                    )}
                  </Fragment>
                )
              )
            : "-"}
        </Avatar.Group>
      ),
    },
    {
      title: "Department",
      dataIndex: "department_name",
      sorter: (a, b) => a?.department_name?.localeCompare(b?.department_name),
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text) => {
        let clname = "";
        if (text?.includes("Paused")) {
          clname = "orange_tag";
        } else if (text?.includes("Pending")) {
          clname = "blue_tag";
        } else if (text?.includes("Approved")) {
          clname = "green_tag";
        } else {
          clname = "defaultTag";
        }
        return text ? (
          <Tag className={`defaultTag ${clname}`}>
            <Badge className={`default_badge`} /> {text}
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: () => (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          className="actionButton"
        >
          <Button>
            <Image
              src="/images/vertical_dots.svg"
              width={3}
              height={15}
              alt="vertical dots"
              preview={false}
            />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const createJob = {
    visibility: jobCreate,
    class: "upload_photo_modal",
    content: (
      <CreateJob
        title={<>Create Job</>}
        onClose={() => setJobCreate(false)}
        btnText="Create Job"
      />
    ),
    width: 600,
    onCancel: () => setJobCreate(false),
  };

  const handleDeleteJob = async () => {
    setDeleteLoading(true);
    setTimeout(() => {
      setIsDeleteModalOpen(false);
      setDeleteLoading(false);
    }, 1000);
  };

  const deleteConfirmationPopup = {
    visibility: isDeleteModalOpen,
    class: "delete_confirmation_modal",
    content: (
      <ConfirmDelete
        title="Confirm Deletion"
        heading="Are you sure you want to delete this job?"
        onClose={() => setIsDeleteModalOpen(false)}
        btnText="Delete job"
        btnApi={handleDeleteJob}
        loading={deleteLoading}
      />
    ),
    width: 520,
    onCancel: () => setIsDeleteModalOpen(false),
  };

  useEffect(() => {
    if (accountData?.company_id) {
      const payload = {
        offset: pagination > 1 ? limit * (pagination - 1) : pagination - 1,
        limit: limit,
        companyId: accountData?.company_id,
        status: undefined,
        roles: undefined,
        departments: undefined,
        employees: undefined,
      };
      dispatch(getJobsList(payload));
    }
  }, [pagination, limit, dispatch, accountData]);

  useEffect(() => {
    if (accountData?.company_id) {
      dispatch(getJobFilters(accountData?.company_id));
    }
  }, [dispatch, accountData?.company_id]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setPagination(currentPage);
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  return (
    <DashboardTemplate loading={jobsListData?.loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="fp_main_details">
            <div className="companyInfo">
              <Meta
                avatar={
                  <Image
                    src="/images/login/company_1.png"
                    width={68}
                    height={68}
                    preview={false}
                  />
                }
                title="Navistar International"
                description="Raleigh, NC"
                className="headingH2"
              />
            </div>
            <div className="actionBtn">
              <Dropdown menu={{ items: categories }} placement="bottomLeft">
                <Button className="icon_dark_btn">
                  Create
                  <ArrowIcon className="angleIcon" />
                </Button>
              </Dropdown>
              <Button
                className="icon_grey_btn"
                icon={<EditIcon />}
                onClick={() => navigate("/company-settings/123")}
              >
                Edit company info
              </Button>
            </div>
          </div>
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <div className="company_actions">
                  <Button
                    className="icon_dark_btn"
                    icon={<JobsIcon />}
                    onClick={() => navigate("/company-detail")}
                  >
                    Jobs
                  </Button>
                  <Button
                    className="icon_white_btn"
                    icon={<RolesIcon />}
                    onClick={() => navigate("/company-detail/roles")}
                  >
                    Roles
                  </Button>
                  <Button
                    className="icon_white_btn"
                    icon={<EmployessIcon />}
                    onClick={() => navigate("/company-detail/employees")}
                  >
                    Employees
                  </Button>
                </div>
                <div className="filter_right">
                  <div
                    className="filter_btn"
                    style={{ display: "flex", gap: "6px" }}
                  >
                    <span className="filterTilte"> Filter: </span>
                    <Select
                      placeholder="Roles"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.role}
                    />
                    <Select
                      placeholder="Holders"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.employee}
                    />
                    <Select
                      placeholder="Departments"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.department}
                    />
                    <Select
                      placeholder="Status"
                      suffixIcon={<DropdownIcon />}
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                      options={jobsFilterOptions?.status}
                    />
                    <Image src="/images/filter_btn.svg" preview={false} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {jobsListData?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={jobsListData?.data}
              pagination={{
                defaultPageSize: limit,
                total: jobsListData?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
            />
          )}

          {jobsListData?.data?.length === 0 && (
            <EmptyCard
              image="/images/members/empty-card-icon.svg"
              title={
                selectedFilters?.status === "deactivated"
                  ? "There are no deactivated members."
                  : "There are no active members."
              }
              description='Click the "Add member" button to add a new member.'
              buttonText="Add member"
              navigateTo="/create-member"
              showOnlyHeading={selectedFilters?.status === "deactivated"}
            />
          )}
        </Col>
      </Row>

      <Popup {...createJob} />
      <Popup {...deleteConfirmationPopup} />
    </DashboardTemplate>
  );
}
