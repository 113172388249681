import { useState } from "react";
import { Button, Card, Col, Form, Row, Input, List } from "antd";
import { CloseIcon, PlusIcon } from "../../../atoms/CustomIcons";

export default function Teams() {
  const [inputs, setInputs] = useState<string[]>([]);
  const [currentInput, setCurrentInput] = useState<string>("");
  const [addNew, setAddNew] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleAddInput = () => {
    if (currentInput.trim() !== "") {
      setInputs([currentInput, ...inputs]);
      setCurrentInput("");
      setAddNew(false);
    }
  };

  const handleDeleteInput = (index: number) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };

  const filteredInputs = inputs.filter((input) =>
    input.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Card className="fp_layout_inner">
      <div className="cardContent" style={{ marginBottom: "48px" }}>
        <Form layout="vertical">
          <Row gutter={[0, 24]}>
            <Col>
              <h4>Teams</h4>
            </Col>
            <Col span={24} className="Add_Departments">
              <div className="actionBar">
                <Input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search departments"
                  className="search_departments"
                />
                <Button
                  onClick={() => setAddNew(true)}
                  className="addNewItem icon_grey_btn"
                >
                  <PlusIcon /> Add New
                </Button>
              </div>

              <div className="departments_list">
                {addNew && (
                  <div>
                    <Input
                      value={currentInput}
                      onChange={(e) => setCurrentInput(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleAddInput();
                        }
                      }}
                      placeholder="Type department name"
                      className="enterNewCompany"
                    />
                  </div>
                )}

                <List
                  dataSource={filteredInputs}
                  renderItem={(item: string, index: number) => (
                    <List.Item
                      actions={[
                        <Button
                          type="link"
                          onClick={() => handleDeleteInput(index)}
                          icon={<CloseIcon />}
                          className="deleteBtn"
                        ></Button>,
                      ]}
                    >
                      {item}
                    </List.Item>
                  )}
                />
              </div>
            </Col>
            <Col className="fp_footer">
              <div>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="icon_dark_btn"
                >
                  Save Changes
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
}
