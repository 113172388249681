import { useEffect } from "react";
import { Button, Layout, Menu, Image, Spin, Input, Dropdown } from "antd";
import type { MenuProps } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getUserDetails } from "../../organisms/MyAccount/ducks/actions";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { SearchIcon } from "../../atoms/CustomIcons";

const { Header, Content } = Layout;
const antIcon = <Loading3QuartersOutlined spin />;

type MenuItem = Required<MenuProps>["items"][number];
type dashboardProps = {
  loading?: boolean;
  children?: React.ReactNode;
};

const DashboardTemplate = (props: dashboardProps) => {
  const { loading, children } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accountData = useAppSelector((state) => state?.account?.accountData);

  // State for notifications
  // const [notifications, setNotifications] = useState<
  //   { id: number; message: string }[]
  // >([
  //   {
  //     id: 1,
  //     message: "Lira Aminoff removed 2 roles from the “Project Manager” job",
  //   },
  //   { id: 2, message: "Ryan Septimus completed a Worksheet session" },
  //   {
  //     id: 3,
  //     message: "Lira Aminoff updated the “Leadership Training” role details",
  //   },
  // ]);

  const logoutHandler = () => {
    localStorage.clear();
    window.location.assign("/login");
  };

  const items: MenuItem[] = [
    {
      label: (
        <Link to="/my-job">
          <img src="/images/userIcon.svg" width={16} alt="" /> My job
        </Link>
      ),
      key: "my-job",
    },
    {
      label: (
        <Link to="/company-detail">
          <img src="/images/settingsIcon.svg" width={16} alt="" /> My company
        </Link>
      ),
      key: "company-detail",
    },
  ];

  const itemsStatus: MenuProps["items"] = [
    {
      label: <Link to="/my-account"> My account details </Link>,
      key: "1",
    },
    {
      label: <Link to="/change-password"> Change password </Link>,
      key: "2",
    },
    {
      label: <span onClick={logoutHandler}> Logout </span>,
      key: "3",
    },
  ];

  useEffect(() => {
    if (
      accountData &&
      Object.keys(accountData).length > 0 &&
      accountData.type === "member"
    ) {
      navigate("/");
    }
  }, [accountData]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const currentPath = location.pathname.split("/")[1];

  // Notification Dropdown Menu
  // const notificationMenu = (
  //   <div className="notofications_menu">
  //     {notifications.length === 0 ? (
  //       <Empty description="No notifications" />
  //     ) : (
  //       <>
  //         <div className="notification_titlebar">
  //           <h4> Notifications</h4>
  //           <span onClick={() => setNotifications([])} className="read_all_btn">
  //             Read all
  //           </span>
  //         </div>
  //         <List
  //           dataSource={notifications}
  //           renderItem={(item) => (
  //             <List.Item
  //               actions={[
  //                 <span
  //                   className="notification_close"
  //                   onClick={() =>
  //                     setNotifications(
  //                       notifications.filter((n) => n.id !== item.id)
  //                     )
  //                   }
  //                 >
  //                   <CloseIcon />
  //                 </span>,
  //               ]}
  //             >
  //               {item.message}
  //             </List.Item>
  //           )}
  //         />
  //       </>
  //     )}
  //   </div>
  // );

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Layout className="main_layout">
        <Header style={{ display: "flex", alignItems: "center" }}>
          <Link to="/" className="logo_area">
            <Image src="/images/login/fp_logo.svg" preview={false} />{" "}
          </Link>
          <Menu mode="horizontal" items={items} selectedKeys={[currentPath]} />
          <div style={{ display: "flex", gap: "16px", marginLeft: "auto" }}>
            <div className="search_filter">
              <Input
                placeholder="Search jobs, roles, employees"
                prefix={<SearchIcon />}
              />
            </div>
            <div className="user_info">
              {/* <Dropdown overlay={notificationMenu} trigger={["click"]}>
                <div className="notificationButton">
                  <Image src="/images/login/bell_icon.svg" preview={false} />
                </div>
              </Dropdown> */}
              <div className="notificationButton">
                <Image src="/images/login/bell_icon.svg" preview={false} />
              </div>
              <Dropdown
                menu={{ items: itemsStatus }}
                trigger={["click"]}
                placement="bottomLeft"
                overlayClassName="ft_dropdown"
              >
                <Button
                  type="text"
                  className="user_info_button"
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="userName">
                    <span> James G. </span>
                    <Image
                      src={"/images/login/userImg.png"}
                      alt="icon"
                      width={36}
                      height={36}
                      preview={false}
                    />
                  </div>
                </Button>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content className="content_area">{children}</Content>
      </Layout>
    </Spin>
  );
};

export default DashboardTemplate;
