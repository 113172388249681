import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HolderOutlined } from "@ant-design/icons";

export function SortableItem(props: {
  data: any;
  updateAPI?: () => void;
  index: number;
}) {
  const { data } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: data.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="sortable_wrap">
      <div ref={setNodeRef} style={style} className="sortable_row">
        <div className="sortable_icon" {...attributes} {...listeners}>
          <HolderOutlined />
        </div>
        <div className="sortable_name">
          <span>{data?.name}</span>
        </div>
      </div>
    </div>
  );
}
