import { Row, Col, Button, Card, Form } from "antd";
import { useForm } from "react-hook-form";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import InputField from "../../../molecules/FormItems/InputField";
import { useEffect, useState } from "react";
import {
  onImageDelete,
  updateAccountProfile,
  uploadUserImage,
} from "../../../organisms/MyAccount/ducks/services";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getUserDetails } from "../../../organisms/MyAccount/ducks/actions";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import FullPageLayout from "../../../templates/FullPageLayout";
import { AxiosError } from "axios";
import { CloseIcon } from "../../../atoms/CustomIcons";
import { useNavigate } from "react-router-dom";
import RadioGroup from "../../../molecules/FormItems/RadioGroup";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
};

export default function CreateCompany() {
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const { control, handleSubmit, setValue } = useForm<FormValues>();

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      first_name: val?.first_name,
      last_name: val?.last_name,
      email: val?.email,
    };
    updateAccountProfile(payload, accountData?.id)
      .then(() => {
        setLoading(false);
        dispatch(getUserDetails());
        SuccessMessage("Profile updated.");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (accountData) {
      setValue("first_name", accountData?.first_name);
      setValue("last_name", accountData?.last_name);
      setValue("email", accountData?.email);
    }
  }, [setValue, accountData]);

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    uploadUserImage({
      owner_id: accountData?.id,
      image: checklistFile?.uid ? checklistFile : undefined,
      image_type: "user_image",
    })
      .then((res) => {
        if (res) {
          SuccessMessage("Image changed.");
          dispatch(getUserDetails());
          setLoading(false);
          setUploadModal(false);
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  function callUserDetailsAPI() {
    dispatch(getUserDetails());
  }

  const radioData: Array<{ label: string; value: string }> = [
    {
      label: "Job",
      value: "job",
    },
    {
      label: "Seat",
      value: "seat",
    },
  ];

  return (
    <FullPageLayout loading={false}>
      <Card className="fp_layout_title">
        <div className="mainTitle">
          <Button
            className="icon_grey_btn"
            icon={<CloseIcon />}
            onClick={() => navigate(-1)}
          >
            Close
          </Button>
          <h3>Create company</h3>
        </div>
      </Card>
      <Card className="fp_layout_content">
        <div className="cardContent">
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4
                      style={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      Logo
                    </h4>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <UploadPhoto
                        uploadAvatar={
                          accountData?.files?.length > 0
                            ? accountData?.files[0].url
                            : "/images/upload-avatar.svg"
                        }
                        imageUploaded={accountData?.files?.length > 0}
                        uploadedImageURL={
                          accountData?.files?.length > 0
                            ? accountData?.files[0].url
                            : undefined
                        }
                        width={88}
                        height={88}
                        saveUpload={saveUpload}
                        loading={loading}
                        uploadModal={uploadModal}
                        setUploadModal={setUploadModal}
                        onImageDelete={() => {
                          onImageDelete(
                            setLoading,
                            SuccessMessage,
                            callUserDetailsAPI,
                            ErrorMessage,
                            accountData?.files.length > 0
                              ? accountData?.files[0]?.id
                              : undefined
                          );
                        }}
                        previewImage={previewImage}
                        checklistFile={checklistFile}
                        modalPreviewImage={modalPreviewImage}
                        setChecklistFile={setChecklistFile}
                        setPreviewImage={setPreviewImage}
                        setModalPreviewImage={setModalPreviewImage}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 533px">
                    <InputField
                      fieldname="company_name"
                      label="Name"
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Enter company name",
                      }}
                    />
                  </Col>
                  <Col flex="0 0 533px">
                    <InputField
                      fieldname="company_location"
                      label="Location"
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Enter company location",
                      }}
                    />
                  </Col>
                  <Col flex="0 0 533px" style={{ marginBottom: "30px" }}>
                    <div className="radioTags">
                      <RadioGroup
                        fieldname="position"
                        label="Label positions as:"
                        control={control}
                        optionsData={radioData}
                        initValue="job"
                      />
                    </div>
                  </Col>
                  <Col className="fp_footer">
                    <div>
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                      >
                        Create company
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </FullPageLayout>
  );
}
